import {requirePermission} from "@/router/index";

export default [
	/**
	 * MAGANG
	 * */
	{
		path: "magang",
		name: "Magang",
		component: () =>
			import(
				/* webpackChunkName: "magang" */
				"../views/Pembelajaran/Magang"
				),
		meta: {
			title: "Magang | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin",
				"Teacher"
			]
		},
		beforeEnter: requirePermission
	},
	{
		path: "magang/detail-magang/:id",
		name: "MagangDetail",
		component: () =>
			import(
				/* webpackChunkName: "magang-detail" */
				"../views/Pembelajaran/Magang/DetailMagang"
				),
		meta: {
			title: "Magang | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin",
				"Teacher"
			]
		},
		beforeEnter: requirePermission
	},
	{
		path: "magang/detail-magang/:id/group/:group_id/atur-anggota",
		name: "MagangAturAnggota",
		component: () =>
			import(
				/* webpackChunkName: "magang-detail-atur-anggota" */
				"../views/Pembelajaran/Magang/DetailMagang/Group/AturAnggota"
				),
		meta: {
			title: "Magang | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin",
				"Teacher"
			]
		},
		beforeEnter: requirePermission
	},
	{
		path: "magang/detail-magang/:id/group/:group_id/jadwal",
		name: "MagangJadwal",
		component: () =>
			import(
				/* webpackChunkName: "magang-detail-atur-jadwal" */
				"../views/Pembelajaran/Magang/DetailMagang/Jadwal/DetailJadwal"
				),
		meta: {
			title: "Magang | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin",
				"Teacher"
			]
		},
		beforeEnter: requirePermission
	},
	// {
	//   path: "magang/setting-group/:id",
	//   name: "Magang",
	//   component: () =>
	//     import(
	//       /* webpackChunkName: "magang" */
	//       "../views/Pembelajaran/Magang/Group/SettingGroup"
	//     ),
	//   meta: {
	//     title: "Magang | ",
	//     allowedRole: [
	//       "Head Master",
	//       "SuperAdmin",
	//       "Vice Principal",
	//       "School Admin",
	//       "Teacher",
	//     ],
	//   },
	//   beforeEnter: requirePermission,
	// },
	// {
	//   path: "magang/report/:id",
	//   name: "Magang",
	//   component: () =>
	//     import(
	//       /* webpackChunkName: "magang" */
	//       "../views/Pembelajaran/Magang/Report/DetailReport"
	//     ),
	//   meta: {
	//     title: "Magang | ",
	//     allowedRole: [
	//       "Head Master",
	//       "SuperAdmin",
	//       "Vice Principal",
	//       "School Admin",
	//       "Teacher",
	//     ],
	//   },
	//   beforeEnter: requirePermission,
	// },
	{
		path: "/pembelajaran/magang/detail-magang/report/:id/info/:group_id",
		name: "MagangReport",
		component: () =>
			import(
				/* webpackChunkName: "magang" */
				"../views/Pembelajaran/Magang/DetailMagang/Report/DetailReport"
				),
		meta: {
			title: "Magang | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin",
				"Teacher"
			]
		},
		beforeEnter: requirePermission
	}
]
